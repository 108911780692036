.modal-90w {
  width: 90%;
  max-width: none !important;
}
.no-mar-between {
  margin: -4px;
  line-height: 1.3;
}
.bord-lft-warning {
  border-left: 4px solid #ffb300;
}
.bord-lft-success {
  border-left: 4px solid #8bc34a;
}
.modal-100w {
  width: 100%;
  max-width: none !important;
  height: 100%;
  max-height: 100% !important;
}
.ng-arrow-left,
.ng-arrow-right {
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: 3;
}
.ng-arrow-right,
.ng-button-close {
  right: 20px;
}
.ng-button-close {
  position: fixed;
  z-index: 3;
  top: 20px;
}
